.container {
  background-color: #f7f7f7;
}
.Panel {
  min-height: 70vh;
  min-width: 60vw;
  justify-content: center;
  border-radius: 25px;
  border: 2px solid rgb(149, 231, 155);
  .title {
    font-size: 2em;
    font-weight: bold;
    color: rgb(39, 39, 39);
    text-align: center;
    padding: 1em;
  }



  .submit {
    display: block;
    width: 30%;
    height: 2.5em;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 2em;
    padding: 0.5em;
    font-size: 1.2em;
    color: #fff;
    background: #000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
      transition: all 0.2s ease-in-out;
      background: rgb(31, 31, 31);
    }
  }
  .result {
    display: block;
    width: 80%;
    height: 2.5em;
    outline: none;
    margin: 0 auto;
    padding: 0.5em;
    font-size: 1.2em;
    color: #000;
    background-color: #f7f7f7;
  }

  .txid_input {
    width: 100%;
    overflow: auto;
    select {
        justify-content: center;
        float:left;
      display: block;
      height:2.66em;
        margin-left: 6%;
      font-size: 18px;
        background-color: #f7f7f7;
        border-style: none;
        border-radius: 5px 0px 0px 5px;
        border: 1px solid rgb(39, 39, 39);
        border-right:none;
        outline: none;
        width: 15%;
    }
    .inputbox {
        display: block;
        width: 70%;
        height: 2.5em;
        border-radius: 0px 5px 5px 0px;
        border-style: none;
        border: 1px solid rgb(39, 39, 39);
        outline: none;
        margin-left:0px;
        padding: 0.5em;
        font-size: 1.2em;
        color: #000;
        background-color: #f7f7f7;
      }
  }
}
