.container {
  background-color: #f7f7f7;
  height: 100vh;
  overflow: scroll;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}
.Panel {
  min-height: 70vh;
  min-width: 60vw;
  margin-top: 1vh;
  max-height: inherit;
  overflow-y: auto;
  justify-content: center;
  border-radius: 25px;
  border: 2px solid rgb(149, 231, 155);
  padding-bottom: 2rem;
  .title {
    font-size: 2em;
    font-weight: bold;
    color: rgb(39, 39, 39);
    text-align: center;
    padding: 1em;
  }

  .inputbox {
    display: block;
    width: 80%;
    height: 2.5em;
    border-radius: 5px;
    border-style: none;
    border: 1px solid rgb(39, 39, 39);
    outline: none;
    margin: 0 auto;
    padding: 0.5em;
    font-size: 1.2em;
    color: #000;
    background-color: #f7f7f7;
  }

  .submit {
    display: block;
    width: 30%;
    height: 2.5em;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 2em;
    padding: 0.5em;
    font-size: 1.2em;
    color: #fff;
    background: #000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
      transition: all 0.2s ease-in-out;
      background: rgb(31, 31, 31);
    }
  }
  .result {
    display: block;
    width: 80%;
    height: 2.5em;
    outline: none;
    margin: 0 auto;
    padding: 0.5em;
    font-size: 1.2em;
    color: #000;
    background-color: #f7f7f7;
  }

  .network_select {
    display:flex;
    justify-content:space-between;
    align-items:center;
  
    .select_box {
      align-items:center;
      text-align: center;
      width: 50%;
      height: 3em;
      padding: 1em;
      background-color: #f7f7f7;
      // border-radius: 25px 0px 0px 25px;
      &.hover {
        color: #ffc107;
        font-weight: bold;
      }
    }

  }

  .tutorial {
    margin: 2rem;
  }
}
