.wrap_main {
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: #f5f5f5;
    margin: 2rem;
    min-height: 70vh;
    justify-content: center;
    border-radius: 25px;
    border: 2px solid rgb(149, 231, 155);
    height: 90vh;
    width: 90vw;
    display: flex;
    flex-direction: column;
    p{
        color: #000;
        margin: 0.5rem;
    }
}