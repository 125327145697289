.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(49, 49, 49, 0.5);
  display: flex;
  z-index: 9999;
  justify-content: center;
  align-items: center;
}

.popup_inner {
  position: relative;
  padding: 32px;
  min-width: 10vw;
  min-height: 50vh;
  background-color: #fff;
  border-radius: 15px;

  .close_button {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 50px;
    height: 25px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
  }
  .metamask_connect {
    display: flex;
    float: left;
    border-radius: 4px;
    background: #f6851b;
    padding: 10px 22px;
    border: none;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 15px;
    cursor: pointer;
  }
  .keplr_connect {
    display: flex;
    float: left;
    border-radius: 4px;
    background: #1bd5f6;
    padding: 10px 22px;
    border: none;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 15px;
    cursor: pointer;
  }
}
