.container {
  background-color: #f7f7f7;
}
.Panel {
  min-height: 70vh;
  min-width: 60vw;
  justify-content: center;
  border-radius: 25px;
  border: 2px solid rgb(149, 231, 155);
  .title {
    font-size: 2em;
    font-weight: bold;
    color: rgb(39, 39, 39);
    text-align: center;
    padding: 1em;
  }

  .inputbox {
    display: block;
    width: 80%;
    height: 2.5em;
    border-radius: 5px;
    border-style: none;
    border: 1px solid rgb(39, 39, 39);
    outline: none;
    margin: 0 auto;
    padding: 0.5em;
    font-size: 1.2em;
    color: #000;
    background-color: #f7f7f7;
  }

  .submit {
    display: block;
    width: 30%;
    height: 2.5em;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 2em;
    padding: 0.5em;
    font-size: 1.2em;
    color: #fff;
    background: #000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
      transition: all 0.2s ease-in-out;
      background: rgb(31, 31, 31);
    }
  }
  .result {
    display: block;
    width: 80%;
    height: 2.5em;
    outline: none;
    margin: 0 auto;
    padding: 0.5em;
    font-size: 1.2em;
    color: #000;
    background-color: #f7f7f7;
  }
}
