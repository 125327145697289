@font-face {
  font-family: 'jf-openhuninn';
  src: local('jf-openhuninn'), url(./fonts/jf-openhuninn-1.1.ttf) format('truetype');
}

* {
  font-family:jf-openhuninn,Arial, Helvetica, sans-serif ;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}